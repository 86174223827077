"use client";
import ProductGrid from "@/components/product/product-grid";
import useGlobalData from "@/hooks/useGlobalData";
import React from "react";
import { useTranslation } from "react-i18next";

const ProductsView = () => {
  const { t } = useTranslation("common");
  const { SaleCategory } = useGlobalData();
  return (
    <div className="px-0 md:px-12 lg:px-26  " >
      <h1 className="text-3xl font-bold text-black py-12">
        {SaleCategory?.name || t("text-all-products")}
      </h1>
      <ProductGrid categoryId={SaleCategory?.id || null} />
    </div>
  );
};

export default ProductsView;
