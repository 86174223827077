import ContentLoader from "react-content-loader";

const ProductCardLoaderMobile = (props) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={100}
    // viewBox="0 0 100 100"
    backgroundColor="#e6e6e6"
    foregroundColor="#f0eded"
    className="w-full"
    {...props}
  >
    <rect x="0" y="0" rx="9" ry="9" width="100%" height="100%" />
  </ContentLoader>
);

export default ProductCardLoaderMobile;
