"use client";
import React from "react";
import Container from "./containers/container";
import { images } from "../constants/images";
import Image from "next/legacy/image";
import { SiWhatsapp } from "react-icons/si";
import useGlobalData from "@/hooks/useGlobalData";
import Link from "next/link";
const ContactUS = () => {
  const { vendorData } = useGlobalData();
  const { socialMediaLinks } = vendorData || [];
  const whatsapp =
    socialMediaLinks?.find((item) => item.type === "whatsapp") || null;
  return (
    <Container className="bg-bgLight flex flex-col items-center justify-center p-8">
      <div className="flex justify-center items-center text-center max-w-screen-2xl gap-8">
        <div className="flex flex-col lg:flex-row justify-center items-center w-full gap-10">
          <div className="hidden lg:flex flex-1 justify-center items-center ">
            <Image
              width={500}
              height={500}
              src={images.contact}
              alt="contact"
              className="object-contain w-3/4 "
            />
          </div>
          <div className="text-headerText text-center flex-1 lg:text-right">
            <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold mb-4">
              אני כאן בשבילכם, <br /> תמיד כשאתם צריכים
            </h1>

            <div className="text-2xl sm:text-2xl md:text-4xl font-semibold">
              <p>די מי משאיר פרטים היום?</p>
              <p>קחו את הוואטסאפ האישי שלי!</p>
            </div>
            {whatsapp ? (
              <Link
                href={whatsapp.link}
                target="_blank"
                className="gap-x-4  flex lg:flex-row items-center text-headerText rounded-lg px-4 py-2 shadow-lg cursor-pointer transition duration-300"
              >
                <div className="text-2xl text-center font-medium rtl">
                  <hr className="border-headerText border-t-4 w-full" />
                  <span className="block">{"הקליקו כאן לצ'אט"}</span>
                  <span className="block">{"ישיר בוואטסאפ"}</span>
                  <hr className="border-headerText border-t-4 w-full" />
                </div>

                <div className="p-4 flex items-center justify-center bg-whatsappGreen rounded-full">
                  <SiWhatsapp color={"#fff"} size={60} />
                </div>
              </Link>
            ) : null}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ContactUS;
