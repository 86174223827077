import cn from "classnames";
import Image from "next/legacy/image";
import useUI from "@/hooks/useUI";
import { Product, ProductProps } from "@/types/dtos/products";
import { useState } from "react";
import Counter from "../counter";
import useCart from "@/hooks/useCart";
import MeasurementsSelector from "./product-measurements-selector";
import useProductSellType from "./hooks/use-product-selltype";
import EventsActions from "@/actions/events";
import { SellType } from "@/types";
import { Button } from "antd";
import { useTranslation } from "@/app/i18n/client";
import { useParams } from "next/navigation";
import useCampaign from "@/hooks/useCampaign";
import { images } from "@/constants/images";

const ProductMobileCard: React.FC<ProductProps> = ({
  product,
  showAnimation = false,
}) => {
  const { lng } = useParams();
  const { addItemToCart, createCartObject, items } = useCart();
  const { t } = useTranslation(lng, "common");

  const eventsActions = EventsActions();
  const { openModal, setModalView, setModalData } = useUI();

  const { additionalSellTypes, defaultSellType } = product || {};
  const {
    step,
    currentSellType,
    quantityComparingToDefaultSellType,
    productSellTypes,
    handleSelectSellType,
  } = useProductSellType({
    defaultSellType,
    additionalSellTypes,
  });

  const cartItem = items.find((item: Product) => item.id === product.id);
  const cartQuantity = cartItem?.quantity;
  const cartItemSellType = cartItem?.sellType;

  const [quantity, setQuantity] = useState(step);

  //@ts-ignore
  const handlePopupView = () => {
    eventsActions.viewProduct(product?.id);
    setModalData({ data: { ...product, quantity } });
    setModalView("PRODUCT_VIEW");
    return openModal();
  };

  const onSelectSellType = (sellType: SellType) => {
    handleSelectSellType(sellType);
    setQuantity(sellType.step);
  };
  const addToCart = () => {
    const item = createCartObject({ ...product, sellType: currentSellType });
    addItemToCart(item, quantity);
  };
  const {
    campaignPrices: { price, basePrice },
    getRepresentableTexts,
  } = useCampaign(product, currentSellType, quantityComparingToDefaultSellType);

  return (
    <div
      className={cn(
        "bg-white flex  flex-col  py-5 w-full overflow-hidden  cursor-pointer border-b-[1px] border-primary relative",
        {
          "transition duration-300 transform rounded-lg hover:shadow-lg hover:-translate-y-2":
            showAnimation,
        }
      )}
    >
      {cartQuantity ? (
        <div className="flex items-center gap-2  absolute top-1 start-0 z-10 ">
          <div
            className="bg-primaryDark w-7 h-7 flex items-center justify-center"
            style={{ borderRadius: "50%" }}
          >
            <span className="text-bgLight font-bold text-xs">
              {cartQuantity}
            </span>
          </div>
          <span className="text-sm font-semibold">
            {cartItemSellType?.text}
          </span>
        </div>
      ) : null}
      <div className="flex items-center justify-between w-full">
        <div className=" flex items-center">
          <Image
            src={product?.mainImg ?? images.placeHolder}
            alt={product?.title}
            width={60}
            height={60}
            onClick={handlePopupView}
            className="object-contain rounded-sm"
          />
          <div className="flex items-start justify-center flex-col px-5 flex-1 text-center">
            <span className="text-black font-bold text-start text-sm">
              {product?.title}
            </span>
            <div className="flex flex-col gap-1">
              {getRepresentableTexts.map((text, index) => (
                <div
                  className="bg-primaryLight rounded-none min-[341px]:rounded-full px-2 w-fit"
                  key={index}
                >
                  <span className="text-xs text-white">{text.mainTitle}</span>
                </div>
              ))}
            </div>
            <div className="flex items-center flex-wrap gap-1">
              {basePrice ? (
                <span className=" text-black text-sm font-bold">
                  {basePrice}
                </span>
              ) : null}
              <span
                className={cn("text-sm text-black", {
                  "text-gray-800 text-xs": !!basePrice,
                })}
              >
                <span className={cn({ "line-through": !!basePrice })}>
                  {`${basePrice ? "/ " : ""}${price}`}
                </span>
              </span>
              <span className="text-xs">{currentSellType?.text}</span>
            </div>

            {productSellTypes?.length > 1 ? (
              <MeasurementsSelector
                currentSelect={currentSellType}
                selectors={productSellTypes}
                handleSelect={onSelectSellType}
                size="small"
              />
            ) : null}
          </div>
        </div>
        <div className="flex items-center flex-col gap-2">
          <Counter
            quantity={quantity}
            variant="text"
            onIncrement={() =>
              setQuantity((prev: number) =>
                parseFloat((prev + step).toFixed(3))
              )
            }
            onDecrement={() =>
              setQuantity((prev: number) =>
                parseFloat((prev !== step ? prev - step : step).toFixed(3))
              )
            }
            disableDecrement={quantity === step}
          />
          <Button
            onClick={addToCart}
            className="!bg-black rounded-full py-0 px-8 h-6"
            type="primary"
          >
            <span className="text-nowrap text-xs text-bgLight">
              {t("text-add-to-cart")}
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProductMobileCard;
