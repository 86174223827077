import ContentLoader from "react-content-loader";

const ProductCardLoader = (props) => (
  <ContentLoader
    speed={2}
    // width={334}
    // height={545}
    // viewBox="0 0 334 545"
    width={100}
    height={100}
    viewBox="0 0 100 100"
    backgroundColor="#e6e6e6"
    foregroundColor="#f0eded"
    className="w-full h-auto rounded-tl-[35px] rounded-br-[35px]"
    {...props}
  >
    <rect x="0" y="0" rx="6" ry="6" width="100%" height="100%" />
  </ContentLoader>
);

export default ProductCardLoader;
