import cn from "classnames";
import Image from "next/legacy/image";
import useUI from "@/hooks/useUI";
import { Product, ProductProps } from "@/types/dtos/products";
import { useState } from "react";
import { motion } from "framer-motion";
import Counter from "../counter";
import useCart from "@/hooks/useCart";
import EventsActions from "@/actions/events";
import useProductSellType from "./hooks/use-product-selltype";
import MeasurementsSelector from "./product-measurements-selector";
import { SellType } from "@/types";
import { Button } from "antd";
import { useTranslation } from "@/app/i18n/client";
import { useParams } from "next/navigation";
import useCampaign from "@/hooks/useCampaign";
import { images } from "@/constants/images";
import Tags from "./tags";
type HoverCardProps = {
  product: Product;
  t: any;
  step: number;
  currentSellType: SellType;
  productSellTypes: any;
  handleSelectSellType: (sellType: SellType, quantity: number) => number;
};

const HoverCard: React.FC<HoverCardProps> = ({
  product,
  t,
  step,
  currentSellType,
  productSellTypes,
  handleSelectSellType,
}) => {
  const { addItemToCart, createCartObject } = useCart();
  const [quantity, setQuantity] = useState(step);
  const addToCart = () => {
    const item = createCartObject({ ...product, sellType: currentSellType });
    addItemToCart(item, quantity);
  };
  const onSelectSellType = (sellType: SellType) => {
    const newQuantity = handleSelectSellType(sellType, quantity);
    setQuantity(newQuantity);
  };

  return (
    <div className="absolute flex items-center justify-center size-full bg-black/50 transition-opacity duration-300">
      <div className="flex items-center w-full flex-col justify-center z-30 gap-3">
        <Counter
          quantity={quantity}
          variant="text"
          isCard={true}
          onIncrement={() =>
            setQuantity((prev: number) => parseFloat((prev + step).toFixed(3)))
          }
          onDecrement={() =>
            setQuantity((prev: number) =>
              parseFloat((prev !== step ? prev - step : step).toFixed(3))
            )
          }
          disableDecrement={quantity === step}
        />
        {productSellTypes?.length > 1 ? (
          <MeasurementsSelector
            currentSelect={currentSellType}
            selectors={productSellTypes}
            handleSelect={onSelectSellType}
            size="small"
            theme="light"
          />
        ) : null}
      </div>
    </div>
  );
};

const ProductCard: React.FC<ProductProps> = ({
  product,
  showAnimation = false,
  onClick = null,
}) => {
  const { addItemToCart, createCartObject } = useCart();

  const eventsActions = EventsActions();
  const { openModal, setModalView, setModalData } = useUI();
  const { lng } = useParams();
  const { items } = useCart();
  const { t } = useTranslation(lng, "common");

  const { additionalSellTypes, defaultSellType, tags } = product || {};
  const {
    step,
    currentSellType,
    productSellTypes,
    handleSelectSellType,
    quantityComparingToDefaultSellType,
  } = useProductSellType({
    defaultSellType,
    additionalSellTypes,
  });
  const cartItem = items.find((item: Product) => item.id === product.id);
  const quantity = cartItem?.quantity;
  const cartItemSellType = cartItem?.sellType;
  const {
    campaignPrices: { price, basePrice },
    getRepresentableTexts,
  } = useCampaign(product, currentSellType, quantityComparingToDefaultSellType);

  const [isHovered, setIsHovered] = useState(false);
  const handlePopupView = () => {
    eventsActions.viewProduct(product?.id);
    setModalData({ data: { ...product, quantity } });
    setModalView("PRODUCT_VIEW");
    return openModal();
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const addToCart = () => {
    const item = createCartObject({ ...product, sellType: currentSellType });
    addItemToCart(item, step);
  };
  return (
    <div
      className={cn(
        "bg-white flex items-center flex-col aspect-[3/4] border border-gray-350 overflow-hidden cursor-pointer  flex-1 rounded-tl-[35px] rounded-br-[35px]",
        {
          "transition duration-300 transform rounded-lg hover:shadow-lg hover:-translate-y-2":
            showAnimation,
        }
      )}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="cursor-pointer w-full flex flex-col items-center justify-center relative h-[164px]">
        <div className="flex w-full items-center justify-between top-2 start-2 z-10 gap-2 px-2">
          {quantity ? (
            <div className="flex items-center gap-2">
              <div
                className="bg-primaryDark w-7 h-7 flex items-center justify-center"
                style={{ borderRadius: "50%" }}
              >
                <span className="text-bgLight font-bold text-xs">
                  {quantity}
                </span>
              </div>
              <span className="text-sm font-semibold">
                {cartItemSellType?.text}
              </span>
            </div>
          ) : null}
          <Tags tags={tags} className="justify-end flex-1" />
        </div>

        {/* Product Image Section */}
        <motion.div
          animate={{
            scale: isHovered ? 1.1 : 1,
            rotate: isHovered ? 5 : 0,
          }}
          className="relative"
          transition={{ type: "spring", stiffness: 300 }}
        >
          <Image
            src={product?.mainImg ?? images.placeHolder}
            alt={product?.title}
            width={250}
            height={150}
            className="object-contain rounded-xl w-full"
          />
        </motion.div>

        {/* Hover Effect (Remains Only for Extra Features, Not for Add to Cart) */}
        {isHovered ? (
          <HoverCard
            product={product}
            t={t}
            step={step}
            currentSellType={currentSellType}
            productSellTypes={productSellTypes}
            handleSelectSellType={handleSelectSellType}
          />
        ) : null}
      </div>

      {/* Product Details Section */}
      <div
        className="flex items-center justify-center flex-col p-4 gap-1 flex-1 text-center z-20"
        onClick={onClick ? () => onClick(product?.id) : handlePopupView}
      >
        <span className="text-black text-sm font-bold text-center line-clamp-2 leading-4">
          {product?.title}
        </span>
        <div className="flex flex-col gap-1">
          {getRepresentableTexts.map((text, index) => (
            <span
              className="bg-primaryLight rounded-full px-2 text-sm max-md:text-xs text-white w-fit"
              key={index}
            >
              {text.mainTitle}
            </span>
          ))}
        </div>
        <div className="flex items-center flex-wrap gap-1">
          {basePrice ? (
            <span className="text-black text-sm font-bold">{basePrice}</span>
          ) : null}
          <span
            className={cn("text-sm text-black", {
              "text-gray-800 text-xs": !!basePrice,
            })}
          >
            <span className={cn({ "line-through": !!basePrice })}>
              {`${basePrice ? "/ " : ""}${price}`}
            </span>
          </span>
          <span className="text-sm">{currentSellType?.text}</span>
        </div>
      </div>

      {/* ---- Move the "Add to Cart" Button Here ---- */}
      <div className="flex flex-col items-center justify-center p-2 w-full">
        <Button
          onClick={addToCart}
          className="w-full rounded-full py-1 px-8 h-8 !bg-primaryDark hover:!bg-primaryLight"
          type="primary"
        >
          <span className="text-nowrap text-sm text-bgLight group-hover:text-black">
            {t("text-add-to-cart")}
          </span>
        </Button>
      </div>
    </div>
  );
};

export default ProductCard;
