"use client";
import React, { useState } from "react";
import { Button, Select } from "antd";
import { useTranslation } from "@/app/i18n/client";
import { useParams } from "next/navigation";
import { CityDto } from "@/types";
import useFetch from "@/hooks/useFetch";
import GateWayActions from "@/actions/gateway";
import Lottie from "lottie-react";
import { MdWrongLocation } from "react-icons/md";
import cn from "classnames";

const ShipmentsCities = ({
  cities,
  className,
}: {
  cities: CityDto[];
  className?: string;
}) => {
  const { lng } = useParams();
  const { t } = useTranslation(lng, "common");
  const gateWayActions = GateWayActions();
  const [selectedCity, setSelectedCity] = useState(null);
  const { fetchData, loading } = useFetch();
  const [isAvailable, setIsAvailable] = useState<boolean>(false);
  const [showResult, setShowResult] = useState<boolean>(false);

  const handleCheck = async () => {
    if (!selectedCity) return;
    const response = await fetchData(async () =>
      gateWayActions.checkCityAvailability(selectedCity)
    );

    const isAvailable = response?.data?.isAvailable;

    setIsAvailable(isAvailable);
    setShowResult(true);
    if (isAvailable) {
    }
  };
  return (
    <div className={cn("flex flex-col items-start gap-2", className)}>
      <div className="flex flex-col items-center gap-4  w-full">
        <Select
          showSearch
          value={selectedCity}
          onChange={(value) => {
            setSelectedCity(value);
            setShowResult(false);
          }}
          placeholder={t("placeholder-city")}
          optionFilterProp="name"
          className="rounded-md border-primary  w-full h-10 text-primary bg-white"
          options={cities}
          fieldNames={{ label: "name", value: "id" }}
        />
        <Button
          type="primary"
          onClick={handleCheck}
          loading={loading}
          className="!bg-primaryLight rounded-md  w-full h-10 px-12 py-4 text-white text-lg"
        >
          {t("text-check")}
        </Button>
      </div>
      {showResult ? (
        <div className="font-semibold text-lg">
          {isAvailable ? (
            <div className="flex items-center gap-5">
              <Lottie
                animationData={require("../../../public/assets/animations/location.json")}
                loop={true}
                className="w-12 h-12"
              />
              <span className="text-white">{t("location-found")}</span>
            </div>
          ) : (
            <div className="text-white flex items-center gap-3">
              <MdWrongLocation size={22} />
              <span>{t("location-not-found")}</span>
            </div>
          )}
        </div>
      ) : (
        <div className="h-8 w-full" />
      )}
    </div>
  );
};

export default ShipmentsCities;
