import { ITag } from "@/types/dtos/products";
import { AnimationType } from "@/utils/Enums";
import cn from "classnames";
import React from "react";

const Tags = ({ tags, className }: { tags: ITag[]; className?: string }) => {
  const animations = (type: AnimationType) => {
    switch (type) {
      case AnimationType.FIRE:
        return "animate-flicker delay-300";
      case AnimationType.PULSE:
        return "animate-pulseBadge delay-150";
    }
  };
  return tags ? (
    <div className={cn("flex-1 flex flex-wrap items-center gap-1", className)}>
      {tags.map((tag, index) => {
        const animate = animations(tag.animationType);
        return (
          <div
            key={index}
            className={cn(
              "text-xs p-1 w-fit rounded-tl-md rounded-br-md border border-gray-50",
              animate
            )}
            style={{
              backgroundColor: tag.backgroundColor,
              color: tag.textColor,
            }}
          >
            <span className="">{tag.name}</span>
          </div>
        );
      })}
    </div>
  ) : null;
};

export default Tags;
