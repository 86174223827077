"use client";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import Image from "next/legacy/image";
import useGlobalData from "@/hooks/useGlobalData";
import dynamic from "next/dynamic";

const SystemMessage = dynamic(() => import("@/components/system-message"), {
  ssr: false,
});

const HomeCarousel = () => {
  const { homePageBanners } = useGlobalData();
  return (
    <div>
      <SystemMessage />
      <Swiper
        pagination={{
          clickable: true,
        }}
        spaceBetween={2}
        modules={[Pagination]}
        className="w-full"
      >
        {homePageBanners?.map((item, index) => (
          <SwiperSlide key={`product-gallery-key-${item.id}`}>
            <div className="col-span-1 transition duration-150 ease-in hover:opacity-90 ">
              <div className="absolute w-full h-full flex items-center justify-center">
                <h1
                  className="font-bold text-4xl xl:text-9xl text-paleYellow text-shadow text-center z-10 select-none"
                  style={{ fontFamily: "Karantina" }}
                >
                  {item?.title}
                </h1>
              </div>
              <div className="relative h-[220px] lg:h-[420px] w-full ">
                <Image
                  src={item.image}
                  alt={`${item?.title}--${index}`}
                  layout="fill"
                  className="object-cover w-full  "
                />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default HomeCarousel;
