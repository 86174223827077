import classNames from "classnames";
import ProductCardLoader from "./product-card-loader";
import ProductCardLoaderMobile from "./product-card-loader-mobile";

const ProductFeedLoader = ({
  limit = 5,
  uniqueKey = "product",
  isDesktop = true,
}) => {
  return (
    <div
      className={classNames(
        isDesktop
          ? "grid grid-cols-1 sm:grid-cols-4 lg:grid-cols-6 gap-8"
          : "flex gap-5 flex-col"
      )}
    >
      {Array.from({ length: limit }).map((_, idx) =>
        isDesktop ? (
          <ProductCardLoader key={idx} uniqueKey={`${uniqueKey}-${idx}`} />
        ) : (
          <ProductCardLoaderMobile
            key={idx}
            uniqueKey={`${uniqueKey}-${idx}`}
          />
        )
      )}
    </div>
  );
};

export default ProductFeedLoader;
