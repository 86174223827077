"use client";
import { useEffect, useCallback, useRef } from "react";
import ProductFeedLoader from "@/components/loaders/product-feed-loader";
import Lottie from "lottie-react";
import emptyList from "../../../public/assets/animations/empty-list.json";
import ProductCard from "./product-card";
import { Spin } from "antd";
import useWindowSize from "react-use/lib/useWindowSize";
import { useSsrCompatible } from "@/utils/hooks/use-ssr-compatible";
import ProductMobileCard from "./product-mobile-card";
import usePagination from "@/hooks/usePagination";
import ProductsActions from "@/actions/products";
import classNames from "classnames";
import { Product } from "@/types/dtos/products";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";
import useGlobalData from "@/hooks/useGlobalData";

type ProductGridProps = {
  className?: string;
  categoryId?: string;
  selectedOrder?: number;
};

const ProductGrid: React.FC<ProductGridProps> = ({
  className = "",
  categoryId,
  selectedOrder,
}) => {
  const [ref, inView] = useInView();
  const { t } = useTranslation("common");
  const productsActions = ProductsActions();
  const { SaleCategory } = useGlobalData();
  const isSaleCategory =
    SaleCategory?.id?.toString() === categoryId && SaleCategory !== null;
  const categoriesIds = categoryId ? [categoryId] : [];
  const loadLock = useRef(false);

  const { data, loading, count, handleLoadMore, loadingMore } =
    usePagination<Product>({
      paginationHandler: productsActions.getProducts,
      key: "products",
      filters: { categoriesIds, sortBy: selectedOrder },
      invokeRender: selectedOrder,
    });
  const { width } = useSsrCompatible(useWindowSize(), {
    width: 0,
    height: 0,
  });
  const isDesktop = width >= 768;
  const hasNextPage = data?.length < count;

  const onScroll = useCallback(async () => {
    if (loading || loadingMore || !hasNextPage) return;

    if (inView && !loadLock.current) {
      loadLock.current = true;
      try {
        await handleLoadMore();
      } finally {
        loadLock.current = false; // Unlock regardless of success/failure
      }
    }
  }, [loading, loadingMore, hasNextPage, handleLoadMore, inView]);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [onScroll]);

  if (!data?.length && !loading)
    return (
      <div className="flex items-center flex-col pb-24">
        <Lottie animationData={emptyList} loop={true} className="w-60 h-60" />
        <span className="font-medium text-xl">{t("text-empty-products")}</span>
      </div>
    );

  return (
    <>
      {loading ? (
        <ProductFeedLoader
          isDesktop={isDesktop}
          limit={20}
          uniqueKey="search-product"
        />
      ) : (
        <div
          className={classNames(
            isDesktop
              ? `grid  grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-6 3xl:grid-cols-7 xl:grid-cols-5 gap-x-3 lg:gap-x-5 xl:gap-x-7 gap-y-3 xl:gap-y-5 2xl:gap-y-8 ${className}`
              : "flex gap-5 flex-col"
          )}
        >
          {data?.map((product, index) => {
            return isDesktop ? (
              <ProductCard
                key={`product-desktop-key-${product?.id}`}
                product={product}
              />
            ) : (
              <ProductMobileCard
                key={`product-mobile-key-${product?.id}`}
                product={product}
              />
            );
          })}
        </div>
      )}

      <div ref={ref} />
      <div className="text-center py-8 xl:py-14">
        {loadingMore || hasNextPage ? <Spin /> : null}
      </div>
      {!hasNextPage && !loading ? (
        <div className="w-full flex items-center justify-center py-4">
          <span className="font-semibold text-lg">{t("text-end_of_list")}</span>
        </div>
      ) : null}
    </>
  );
};

export default ProductGrid;
