import { Product } from "@/types/dtos/products";
import { useMemo } from "react";
import usePrice from "./usePrice";
import { GetItemDiscount } from "@/lib/calculations/discountCalculator";
import { SellType } from "@/types";

const useCampaign = (
  product: Product,
  currentSellType: SellType,
  quantityComparingToDefaultSellType: number
) => {
  const { price, oldPrice } = product || {};
  const { itemDiscount, hasDiscount } = GetItemDiscount(
    product,
    currentSellType
  );
  const { price: priceWithoutDiscount, basePrice: basePriceWithoutDiscount } =
    usePrice({
      amount: Number(price) * quantityComparingToDefaultSellType,
      baseAmount: Number(oldPrice) * quantityComparingToDefaultSellType,
    });
  const { price: basePriceWithDiscount, basePrice: priceWithDiscount } =
    usePrice({
      amount: itemDiscount,
      baseAmount: Number(price),
    });

  const getRepresentableTexts = useMemo(() => {
    const { campaignItems } = product || {};
    const texts = campaignItems?.map((campaign) => campaign.representableTexts);
    return texts || [];
  }, [product]);

  return {
    campaignPrices: {
      price: hasDiscount ? priceWithDiscount : priceWithoutDiscount,
      basePrice: hasDiscount ? basePriceWithDiscount : basePriceWithoutDiscount,
    },
    getRepresentableTexts: hasDiscount ? [] : getRepresentableTexts,
  };
};

export default useCampaign;
